import React, { useState } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import { FaChevronRight } from "react-icons/fa"

import SEO from "../components/seo"
import FileBrowser from "../components/FileBrowser";

const blockVariants = {
  // initial: { y: 20, opacity: 0 },
  // enter: { y: 0, opacity: 1, ease: [0.43, 0.13, 0.23, 0.96] },
  // exit: {
  //   y: 20,
  //   opacity: 0,
  //   transition: { duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] },
  // },
}

const IndexPage = ({ location }) => {
  const [overviewVisible, showOverview] = useState(false)

  return (
    <motion.article
      initial="initial"
      animate="enter"
      exit="exit"
      variants={{
        exit: {
          opacity: 0,
          transition: { staggerChildren: 0.1 },
        },
        enter: {
          opacity: 1,
          transition: { staggerChildren: 0.1 },
        },
        initial: { opacity: 0 },
      }}
      className="relative flex flex-col flex-1 ease-in-out main transition-slowest transition-color"
    >
      {/* <SEO title="Home" /> */}
      <motion.div className="flex flex-col flex-1 p-6 overflow-hidden md:p-10">
        <motion.header className={`relative mb-4`} variants={blockVariants}>
          <div className="flex items-center text-xl leading-none">
            <span className="font-medium">6lackbox</span>
            <FaChevronRight className="w-3 h-3 mx-2 text-gray-400 " />
            <span className="font-medium text-gray-400">6lackbox</span>
          </div>
          <div className="block mt-2 sm:hidden">
            <span className="block text-xs text-theme-white">Only you</span>
            <button
              type="button"
              class="inline-flex justify-center rounded-md px-4 py-1 mt-2 bg-theme-highlight text-sm leading-5 text-theme-darkest hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
            >
              Share
            </button>
          </div>
          <button className="hidden ml-auto sm:block">
            <img
              src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=144&amp;h=144&amp;q=80"
              alt=""
              className="object-cover w-8 h-8 rounded-full"
            />
          </button>
        </motion.header>
        <motion.div className="hidden mb-10 sm:flex" variants={blockVariants}>
          <div className="flex flex-col flex-1 w-0 overflow-hidden">
            <div className="flex py-3 mb-5 border-b border-theme-mid">
              <h4 className="text-xs">Overview</h4>
              <button
                className="ml-auto text-xs text-theme-highlight"
                onClick={() => showOverview(!overviewVisible)}
              >
                {overviewVisible ? "Hide" : "Show"}
              </button>
            </div>
            <div
              className={`text-base text-theme-white mb-10 ${
                overviewVisible ? "block" : "hidden"
              }`}
            >
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur
                et. Donec id elit non mi porta gravida at eget metus. Sed
                posuere consectetur est at lobortis. Nullam id dolor id nibh
                ultricies vehicula ut id elit.
              </p>
            </div>
            <FileBrowser />
          </div>
          <div className="relative z-10 flex-col flex-grow flex-shrink-0 hidden w-full max-w-xs pt-16 pl-10 lg:flex">
            <div className="relative block pt-48 rounded bg-theme-dark">
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <span className="text-xs text-theme-white">
                  Select a file to see more details
                </span>
              </div>
            </div>
            <div className="pb-6 mt-12 border-b border-theme-mid">
              <button
                type="button"
                class="inline-flex justify-center w-full rounded-md border border-theme-light px-4 py-2 bg-transparent text-sm leading-5 text-theme-offwhite hover:text-theme-highlight hover:border-theme-highlight focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
              >
                Share folder
              </button>
              <span className="block mt-6 text-xs">Only you have access</span>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.article>
  )
}

export default IndexPage

// export const pageQuery = graphql`
//   query allPortfolioItems {
//     allPrismicPortfolioItem {
//       edges {
//         node {
//           uid
//           first_publication_date
//           last_publication_date
//           data {
//             title {
//               text
//             }
//             meta_short {
//               html
//               text
//             }
//             cover_image {
//               url
//               copyright
//               alt
//               localFile {
//                 childImageSharp {
//                   fluid(maxWidth: 1200, quality: 90) {
//                     ...GatsbyImageSharpFluid_withWebp
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
